import BaseApiServices from "@/services/BaseApiServices";

class QuestionsAPIService extends BaseApiServices{


    async getQuestionFeedback({ gameId, cardId, signal = null }) {

        const url = `/games/questions/getQuestionFeedback/${gameId}/${cardId}`;

        const data = await this.getAxiosInstance().get(url, {signal});

        return data;
    }

}

export default new QuestionsAPIService();
