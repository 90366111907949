<template>
    <v-container class="px-0">

        <h2
            class="title-post"
            v-if="card && card.question"
            v-text="card.question"></h2>

        <div
            class="d-flex justify-center"
            style="min-height: 200px"
            v-if="card && card.image"
        >
            <v-fab-transition>
                <v-img

                    :src="getImagePath(card.image)"
                    height="200px"
                    contain
                />
            </v-fab-transition>
        </div>

        <div class="d-flex justify-center flex-wrap">
            <v-btn
                v-if="card.feedforward"
                color="primary"
                text
                @click="seeFeedForward"
                x-large
            >
                <v-icon class="mr-3" x-large>
                    mdi-information
                </v-icon>
                <span v-text="$t('games.questions.seeFeedFordward')" />
            </v-btn>

            <v-btn
                v-if="disableQuestion && card.feedback"
                class="ml-3"
                color="primary"
                text
                @click="seeFeedback"
                x-large
            >
                <v-icon class="mr-3" x-large>
                    mdi-message-alert
                </v-icon>
                <span v-text="$t('games.questions.seeFeedBack')" />
            </v-btn>
        </div>

        <v-list rounded :disabled="disableQuestion">
            <v-subheader class="text-small"
                         v-text="disableQuestion ? 'Opciones': 'Seleccione la opción' "/>

            <v-list-item-group
                v-model="selectedItem"
                color="primary"
            >
                <v-list-item
                    class="answer"
                    v-for="(item, i) in answers"
                    :key="i"
                >
                    <v-list-item-icon>
                        <v-icon>{{ getIcon(i) }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon
                        v-if="optionsStyle[i] && optionsStyle[i].icon"
                        class="ma-0 pa-0"
                    >
                        <v-icon :color="optionsStyle[i].color"
                                x-large class="ma-1 pa-0">
                            {{ optionsStyle[i].icon }}
                        </v-icon>
                    </v-list-item-icon>

                </v-list-item>
            </v-list-item-group>
        </v-list>

    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "@/plugins/axios";
import QuestionsAPIService from "@/services/QuestionsAPIService";

export default {
    name: 'currentQuestions',
    components: {},
    props: {
        gameId: {
            type: Number,
            required: true
        },
        card: {
            type: Object,
            requiered: true
        },
        answer: {
            type: Object
        },
        currentSolution: {
            type: String
        },
        isAllowedEditQuestion: {
            type: Boolean
        }
    },
    data() {
        return {
            selectedItem: null,
            editingSelectedItem: true
        }
    },
    watch: {
        card: {
            handler(newValue, oldValue) {
                this.setCurrentAnswer(null)
            }
        },
        answer: {
            handler(newValue, oldValue) {
                this.setCurrentAnswer(newValue?.answered)
            }
        }
    },
    computed: {
        disableQuestion(){
            return !this.isAllowedEditQuestion;
        },
        answers() {
            const c = this.card;
            return [c.answer_1, c.answer_2, c.answer_3, c.answer_4, c.answer_5]
                .filter(item => item);
        },
        optionsStyle() {
            const answers = this.answers;
            const countSolutions = Math.max(5, answers.length);
            const arrayStyles = Array(countSolutions).map(item => ({icon: null, color: null}));

            const solution = this.currentSolution;
            if (solution) {
                arrayStyles[(+solution) - 1] = {icon: 'mdi-check', color: 'success'};

                const answer = this.answer?.answered;
                if (answer) {
                    if (+answer !== +solution) {
                        arrayStyles[(+answer) - 1] = {icon: 'mdi-close-circle-outline', color: 'danger'};
                    }
                }
            }

            return arrayStyles;
        },
        ...mapGetters(['getUser'])
    },
    async mounted() {
        this.setCurrentAnswer(this.answer?.answered)

        /*this.$watch('selectedItem', (selectedItem) => {
            if (!this.editingSelectedItem) {
                this.answerQuestion(selectedItem);
            }
        });

        this.editingSelectedItem = false;*/

    // console.log('optionsStyle', this.optionsStyle)
    },
    methods: {
        async answerQuestion() {
            const optionSelected = this.selectedItem;
            if(!optionSelected && optionSelected !== 0) return null;

            const option = optionSelected + 1;

            try {
                const url = `/games/questions/answer/${this.gameId}/${this.card.id}`;

                const result = await axios
                    .post(url, {answer: option});

                return result.data;

                // this.$emit('changeAnswer', result.data);

            } catch (error) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorSavingData'));

                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        },
        setCurrentAnswer(number) {
            this.editingSelectedItem = true;

            if (number != null) {
                this.selectedItem = (+number) - 1;
            } else {
                this.selectedItem = null;
            }

            this.$nextTick(() => {
                this.editingSelectedItem = false;
            })

        },
        getIcon(index) {
            const cChar = String.fromCharCode('a'.charCodeAt(0) + index);
            return `mdi-alpha-${cChar}-circle-outline`
        },
        getImagePath(image) {
            return image ? process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=games/cards/' + image : '';
        },

        setUserState(user, state) {
            //this.$set(this.usersActives, user.id + '', state);
        },
        async seeFeedForward(){

            const info = this.card.feedforward;
            const title = this.$t('common.infoTitle');

            this.$emit('showInfoDialog', { title, info });
        },
        async seeFeedback(){

            this.$store.dispatch('loading/start')
            try {
                const gameId = this.gameId;
                const cardId = this.card.id;

                const result = await QuestionsAPIService.getQuestionFeedback({ gameId, cardId, signal: null});

                const info = result?.data?.feedback;

                if(info){
                    const title = this.$t('games.questions.seeFeedBack');

                    this.$emit('showInfoDialog', { title, info });
                }else{
                    this.$store.dispatch('snackbar/error', this.$t('games.questions.feedBackDontAvailable'));
                }


            } catch (e) {
                // if(e.name !== 'TypeError')
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }

            this.$store.dispatch('loading/end')
        }
    }
}
</script>

<style scoped>
.answer {
  min-height: 30px;
}
</style>
