<template>
    <v-container class="px-0">

        <gameStatusComponent v-if="gameStatus" :gameStatus="gameStatus"/>
        <div
            class="mb-0 pb-0"
        >
            <currentQuestions
                v-if="currentCard"
                ref="currentQuestions"
                :card="currentCard"
                :gameId="gameId"
                :answer="currentAnswer"
                :currentSolution="currentSolution"
                :isAllowedEditQuestion="isAllowedEditQuestion"
                @changeAnswer="changeAnswer"
                @showInfoDialog="showInfoDialog"
            />

        </div>

        <div class="questions-pagination d-flex flex-wrap justify-center mb-2">
            <v-btn
                v-for="(card, index) in cards"
                :key="index"
                :color="(index + 1) !== currentCardNumber ? pageButtonStyles[index]?.color : getColorSecondary"
                fab
                small
                :outlined="!pageButtonStyles[index].done"
                dark
                v-text="(index + 1)"
                :class="{
                    'ml-2' : index > 0,

                }"
                @click="setCard(index + 1)"
            />
        </div>

        <div class="buttons-control"
             v-if="currentCard">

            <c-btn
                :disabled="!hasPrevious"
                text
                @click="showPreviousCard"
            >
                {{ $t('games.questions.buttons.previous') }}
            </c-btn>
            <c-btn
                class="ml-4"
                @click="answerQuestion"
                :outlined="isGameFinished"
                :text="isGameFinished"
                :dark="!isGameFinished"
                :disabled="!hasNext && isGameFinished"
            >
                {{ $t( hasNext ? 'games.questions.buttons.continue' : 'games.questions.buttons.finish' ) }}
            </c-btn>

            <c-btn
                v-if="isGameFinished"
                class="ml-4"
                @click="exitFromGame"
                :outlined="false"
                :text="false"
                :dark="true"
            >
                {{ $t('games.questions.buttons.exit') }}
            </c-btn>
        </div>

        <confirm-dialog
            ref="confirm"
        />
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import {mapGetters} from "vuex";
import currentQuestions from "@/components/games/questions/currentQuestions";

export default {
    name: 'questionsComponnent',
    components: {
        currentQuestions,
        gameStatusComponent: () => import("@/components/games/questions/gameStatus")
    },
    props: {
        gameId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            urlMain: '/games/questions/getMyCurrentState/' + this.gameId,
            cards: [],
            answered: {},
            /*allowModifyGameAfterFinishing: null,
      isGameEndedForUser: null,*/
            isAllowedEditGame: false,
            cardsSolutions: null,
            currentCardNumber: 0,
            gameStatus: null
        }
    },
    computed: {
        currentCard() {
            if (this.currentCardNumber > 0)
                return this.cards[this.currentCardNumber - 1];

            return null;
        },
        currentAnswer() {
            const card = this.currentCard;
            if (card) {
                // console.log('currentAnswer', this.answered);
                const answer = this.answered[card.id];

                return answer;
            }

            return null;
        },
        currentSolution() {
            const cardsSolutions = this.cardsSolutions;
            if (cardsSolutions) {
                const card = this.currentCard;
                if (card) {
                    // console.log('currentAnswer', this.answered);
                    const solution = cardsSolutions[card.id];
                    return solution?.answer_ok;
                }
            }
            return null;
        },
        hasNext() {
            return this.cards.length > this.currentCardNumber;
        },
        hasPrevious() {
            return this.currentCardNumber > 1;
        },
        pageButtonStyles() {
            const defaultColor = this.getColorPrimary;
            const arrayStyles = Array(this.cards.length);
            //arrayStyles.fill({color: defaultColor});


            this.cards.forEach((card, index) => {
                const answered = this.answered[card.id]?.answered;

                const done = !!answered;
                arrayStyles[index] = {color: defaultColor, done};

                if (this.cardsSolutions) {
                    if (answered) {
                        arrayStyles[index].color = (answered === this.cardsSolutions[card.id]?.answer_ok) ? 'success' : 'danger';
                        // console.log('comparison', arrayStyles[index].color, answered, this.cardsSolutions[card.id]?.answer_ok)
                    }
                }
            });

            // console.log('pageButtonStyles', arrayStyles)
            return arrayStyles;

        },
        isAllowedEditQuestion(){
            return this.isAllowedEditGame && !this.answered[this.currentCard?.id];
        },
        isGameFinished(){
            return (!this.isAllowedEditGame || this.gameStatus?.finished_at) ? true : false;
        },
        ...mapGetters(['getUser', 'getColorPrimary', 'getColorSecondary'])
    },
    async created() {
        await this.fetchData();


    },
    destroyed() {

    },
    methods: {

        async fetchData() {
            this.$store.dispatch('loading/start');

            try {
                const data = await axios
                    .get(this.urlMain);

                this.initialize(data.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }

            this.$store.dispatch('loading/end');
        },
        initialize(data) {
            if (data.cards)
                this.cards = data.cards;

            const answeredMap = {};
            data.answered?.forEach(ans => {
                answeredMap[ans.card_id] = ans;
            });
            this.answered = answeredMap;

            // this.allowModifyGameAfterFinishing = Boolean(data.allowModifyGameAfterFinishing);
            // this.isGameEndedForUser = Boolean(data.isGameEndedForUser);

            this.isAllowedEditGame = data.isAllowedEditGame;

            const cardsSolutionsMap = data.cardsSolutions?.length ? {} : null;
            data.cardsSolutions?.forEach(cardS => {
                cardsSolutionsMap[cardS.id] = cardS;
            });
            this.cardsSolutions = cardsSolutionsMap;


            if (data.answered.length < this.cards.length) {
                this.currentCardNumber = data.answered.length;
            }

            if (data.gameStatus) {
                this.gameStatus = data.gameStatus;
                this.setCard(1);
            } else {
                this.showNextCard();
            }

        },
        /*getImagePath(image) {
        return image ? process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=' + image : '';
    },*/
        setCard(index) {
            this.currentCardNumber = index;
        },
        async answerQuestion() {
            if (this.isAllowedEditQuestion) {
                const result = await this.$refs.currentQuestions.answerQuestion();
                if(result){
                    this.changeAnswer(result.answer);

                    if(result.feedback)
                        this.showFeedback(result.feedback);
                }

            }

            if (this.hasNext)
                this.showNextCard();
            else
                this.finishGame();
        },
        showNextCard() {
            if (this.hasNext) {
                this.setCard(this.currentCardNumber + 1);
            }
        },
        showPreviousCard() {
            if (this.hasPrevious) {
                this.setCard(this.currentCardNumber - 1);
            }
        },
        async finishGame() {
            if (!this.isAllowedEditGame) {
                return;
            }


            try {
                this.$store.dispatch('loading/start')

                const url = `/games/questions/finishGame/${this.gameId}`;

                const result = await axios
                    .post(url);

                if (result.status === 200) {
                    this.initialize(result.data);
                }

                this.$store.dispatch('loading/end')

            } catch (error) {
                this.$store.dispatch('loading/end')

                let msg = 'common.errorSavingData';
                if (error.response) {
                    const firstError = error.response?.data?.errors?.length && error.response.data.errors[0]?.detail;
                    if (firstError) {
                        msg = `games.questions.errors.${firstError}`

                        if (firstError === 'need_complete') this.goToQuestionWithoutAnswer();
                    }
                    // console.log(firstError) // "invalid_credentials"
                    // console.log(error.response.status)
                }

                this.$store.dispatch('snackbar/error', this.$t(msg));
            }

        },
        goToQuestionWithoutAnswer() {
            const answered = this.answered;
            const foundIndex = this.cards.findIndex(card => !answered[card.id]?.answered);
            if (foundIndex !== -1) {
                this.setCard(foundIndex + 1);
            }

        },
        changeAnswer(answer) {
            if(!answer)return null;

            if (!this.answered) this.answered = {};

            this.$set(this.answered, answer?.card_id, answer);
        },
        async showInfoDialog( { title, info} ){
            const options = {
                color: "primary",
                width: 800,
                zIndex: 200,
                noCancel: true,
                noOk: false
            }

            if(!title) title = this.$t('common.infoTitle');

            await this.$refs.confirm.open(
                title,
                info,
                options
            )
        },
        showFeedback(feedback){
            const info = feedback;
            this.showInfoDialog({info})
        },
        exitFromGame(){
            this.$router.push('/');
        }
    }
}
</script>

<style scoped lang="scss">
.buttons-control{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > * {
        margin-top: 10px;
    }
}

@media (max-width: 600px){
    .buttons-control{
        justify-content: center;
    }
}

</style>
